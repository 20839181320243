import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialYoutubeInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path
          d="M512,0C229.23,0,0,229.23,0,512c0,282.771,229.229,512,512,512c282.77,0,512-229.229,512-512C1024,229.23,794.77,0,512,0z
		 M800.724,533.283c0,46.647-5.756,93.295-5.756,93.295s-5.634,39.725-22.917,57.217c-21.92,22.959-46.49,23.074-57.758,24.418
		c-80.666,5.83-201.793,6.014-201.793,6.014s-149.874-1.368-195.99-5.791c-12.83-2.406-41.633-1.682-63.562-24.641
		c-17.283-17.492-22.907-57.217-22.907-57.217s-5.765-46.647-5.765-93.295V489.55c0-46.648,5.765-93.296,5.765-93.296
		s5.624-39.724,22.907-57.217c21.929-22.96,46.49-23.074,57.759-24.417c80.668-5.831,201.668-5.831,201.668-5.831h0.25
		c0,0,121.003,0,201.669,5.831c11.268,1.344,35.838,1.458,57.758,24.417c17.283,17.493,22.917,57.217,22.917,57.217
		s5.756,46.648,5.756,93.296V533.283z"
        />
        <polygon points="452.988,586.278 608.721,505.577 452.961,424.312 	" />
      </g>
    </g>
  </Svg>
);

export default SocialYoutubeInvertedIcon;
