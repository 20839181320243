import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialInstagramInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path
          d="M512,0C229.2,0,0,229.2,0,512s229.2,512,512,512s512-229.2,512-512S794.8,0,512,0z M804.3,633.2
	c-1.398,31.3-6.398,52.7-13.7,71.398C783.1,724,773,740.3,756.7,756.7C740.4,773.1,724,783.1,704.6,790.6
	c-18.698,7.302-40.1,12.2-71.398,13.7c-31.4,1.4-41.3,1.8-121.2,1.8s-89.9-0.3-121.2-1.8c-31.3-1.398-52.7-6.398-71.398-13.7
	C300,783.1,283.7,773,267.3,756.7c-16.3-16.4-26.4-32.8-33.9-52.102c-7.3-18.698-12.2-40.1-13.7-71.398
	c-1.4-31.4-1.8-41.4-1.8-121.2c0-79.9,0.3-89.9,1.8-121.2c1.4-31.3,6.4-52.7,13.6-71.4c7.5-19.4,17.6-35.7,33.9-52.1
	c16.4-16.3,32.8-26.4,52.1-33.9c18.7-7.3,40.102-12.2,71.4-13.7c31.398-1.4,41.3-1.8,121.2-1.8c79.898,0,89.898,0.3,121.198,1.8
	c31.302,1.4,52.7,6.4,71.4,13.6c19.4,7.5,35.7,17.6,52.1,33.9c16.4,16.4,26.4,32.7,33.9,52.1c7.3,18.7,12.2,40.1,13.7,71.4
	C805.6,422.1,806,432,806,511.9C806,591.8,805.7,601.8,804.3,633.2z"
        />
        <path
          d="M751.3,393.2c-1.2-28.6-6.1-44.3-10.1-54.6c-5.3-13.7-11.7-23.5-22-33.8c-10.3-10.3-20.102-16.7-33.8-22
	c-10.4-4-26-8.8-54.602-10.1c-31-1.4-40.3-1.7-118.8-1.7s-87.8,0.3-118.8,1.7c-28.7,1.3-44.3,6.1-54.602,10.1
	c-13.698,5.3-23.5,11.7-33.8,22c-10.3,10.3-16.7,20.1-22,33.8c-4,10.4-8.8,26-10.1,54.6c-1.4,31-1.7,40.3-1.7,118.8
	s0.3,87.8,1.7,118.8c1.3,28.7,6.1,44.3,10.1,54.602c5.3,13.698,11.7,23.5,22,33.8s20.102,16.7,33.8,22c10.4,4,26,8.8,54.602,10.1
	c31,1.4,40.3,1.7,118.8,1.7s87.8-0.3,118.8-1.7c28.7-1.3,44.3-6.1,54.602-10.1c13.698-5.3,23.5-11.7,33.8-22s16.7-20.102,22-33.8
	c4-10.4,8.8-26,10.1-54.602c1.4-31,1.7-40.3,1.7-118.8S752.7,424.2,751.3,393.2z M512,662.9c-83.4,0-151-67.602-151-151
	c0-83.4,67.6-151,151-151s151,67.6,151,151C662.9,595.4,595.4,662.9,512,662.9z M668.9,390.3c-19.5,0-35.302-15.8-35.302-35.3
	s15.802-35.3,35.302-35.3s35.3,15.8,35.3,35.3S688.4,390.3,668.9,390.3z"
        />
        <path d="M512,413.9c-54.2,0-98,43.9-98,98c0,54.198,43.9,98,98,98c54.2,0,98-43.9,98-98C610,457.8,566.1,413.9,512,413.9z" />
      </g>
    </g>
  </Svg>
);

export default SocialInstagramInvertedIcon;
