import type { ReactElement, ChangeEvent, KeyboardEvent } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { setCookie } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import FocusLock from 'react-focus-lock';
import { Button } from '../core/button/Button';
import CheckIcon from '../core/icons/CheckIcon';
import { colors, media } from '../core/styles';
import { Switch } from '../core/form';
import { Typography } from '../core/typography/Typography';
import type { GlobalCookieWallSettings as GlobalCookieWallSettingsSchema } from '../../../amplienceTypes/schemas/imported/global-cookie-wall-settings-schema';
import { ABDCT, COCO, COCO_COOKIES_EXPIRATION } from '../../../utilities/constants/cookies';
import { useAppContext } from '../../../utilities/context/static/AppContext';
import type { Maybe, Scalars } from '../../../utilities/graphql/codegen';
import CookieWallElement from './CookieWallElement';
import CookieWallV2Element from './CookieWallV2Element';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { useDataLayerContext } from '../../../utilities/context/static/DataLayerContext';
import { PageTypes } from '../../../utilities/constants';

interface CookieWallSettingsProps {
  close: (coco: string) => void;
}

type CookieEvent = 'cookie-accept' | 'cookie-change' | 'cookie-reject';

const S = {
  CookieWrapper: styled.div<{ $enableCookieWallV2?: Maybe<Scalars['Boolean']> }>`
    position: fixed;
    inset: 0;
    display: flex;
    align-items: ${({ $enableCookieWallV2 }) => ($enableCookieWallV2 ? 'flex-end' : 'center')};
    justify-content: center;
    min-height: auto;
    overflow-y: auto;
    z-index: 10019;
    backdrop-filter: blur(2px);
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-backdrop-filter: blur(2px);

    @media ${media.greaterThan('sm')} {
      padding-block: 34px;
      align-items: flex-end;
    }
  `,

  Cookie: styled.div<{ $settingsOpen: boolean; $enableCookieWallV2?: Maybe<Scalars['Boolean']> }>`
    width: 100%;
    max-width: 960px;
    margin: ${({ $enableCookieWallV2 }) => ($enableCookieWallV2 ? 'auto 10px auto' : '0 10px')};
    padding: ${({ $enableCookieWallV2 }) => ($enableCookieWallV2 ? '16px' : '30px')};
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.BORDER_GREY};

    @media ${media.greaterThan('sm')} {
      max-width: ${({ $settingsOpen, $enableCookieWallV2 }) =>
        // eslint-disable-next-line no-nested-ternary
        $settingsOpen ? '455px' : $enableCookieWallV2 ? '701px' : '240px'};
      margin: ${({ $enableCookieWallV2 }) => ($enableCookieWallV2 ? 'auto auto 0' : '0 auto')};
    }
  `,

  CookieTitle: styled(Typography)`
    margin-bottom: 25px;
    font-size: 21px;
    line-height: 1em;
    text-transform: none;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 30px;
    }
  `,

  CookieContent: styled(Typography)<{ $enableCookieWallV2?: Maybe<Scalars['Boolean']> }>`
    margin-bottom: 13px;
  `,

  YesToAllBtn: styled(Button)`
    width: 100%;
    order: 2;

    @media ${media.greaterThan('sm')} {
      order: 1;
    }
  `,

  ExternalLink: styled.a`
    text-decoration: underline;
    color: ${colors.BLUE_CHARCOAL};

    &:hover,
    &:focus {
      color: ${colors.SUCCESS_BLUE};
    }
  `,

  Subtitle: styled(Typography)`
    margin: 10px 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1em;
    text-transform: none;
  `,

  SettingGroup: styled.div`
    padding-top: 10px;
    display: flex;
  `,

  OnOffSwitch: styled.div`
    flex: 0 0 50px;
  `,

  Switch: styled(Switch)`
    margin-top: -3px;
  `,

  CheckMark: styled.div`
    width: 18px;
    height: 18px;
    margin: auto;
  `,

  OnOff: styled(Typography)`
    margin: 0 10px;
    min-width: 30px;
    font-weight: 700;

    @media ${media.greaterThan('sm')} {
      margin: 0 20px;
    }
  `,

  Buttons: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15px;

    @media ${media.greaterThan('sm')} {
      flex-direction: row;
    }
  `,

  Link: styled.a`
    text-decoration: underline;
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  YesBtn: styled(Button)`
    width: 100%;
    order: 1;

    @media ${media.greaterThan('sm')} {
      order: 2;
    }
  `,
};

export const CookieWallSettings = ({
  close,
  title,
  titleV2,
  content,
  contentV2Paragraph1,
  contentV2Paragraph2,
  contentV2Paragraph3,
  affiliates,
  affiliatesLink,
  clickHere,
  clickHereLink,
  ourPartners,
  ourPartnersLink,
  cookieStatement,
  privacyStatement,
  privacyStatementLink,
  changeSettings,
  moreInfo,
  confirmation,
  yesBtn,
  yesToAllBtn,
  preferencesBtn,
  refuseOptionalBtn,
  acceptAllBtn,
  onLabel,
  offLabel,
  necessaryCookiesTitle,
  necessaryCookiesContent,
  analyticsCookiesTitle,
  analyticsCookiesContent,
  marketingRetargetingCookiesTitle,
  marketingRetargetingCookiesContent,
}: CookieWallSettingsProps & GlobalCookieWallSettingsSchema): ReactElement => {
  const { country } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const {
    configuration: { enableCookieWallV2 },
  } = useStaticContext();

  const { t } = useTranslation('common', { keyPrefix: 'globalCookieWallSettings' });

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [cookieSettings, setCookieSettings] = useState({
    analytics: false,
    marketingAndRetargeting: false,
  });

  const setCocoCookies = (all: boolean, cookieEvent: CookieEvent) => {
    const cookie = all
      ? '11111'
      : `${cookieSettings.marketingAndRetargeting ? '11' : '00'}${
          cookieSettings.analytics ? '1' : '0'
        }11`;

    setCookie(COCO, cookie, { expires: COCO_COOKIES_EXPIRATION });

    if (all || cookieSettings.analytics) {
      setCookie(ABDCT, false);
    } else {
      setCookie(ABDCT, true);
    }

    pushToDataLayer(
      {
        events: {
          category: 'cookies',
          action: cookieEvent.replace('cookie-', ''),
          label: cookie,
        },
        user: {
          consentLevel: cookie,
        },
        page: {
          countryCode: country.toUpperCase(),
          pageType: window?.AppSettings?.pageType?.toLowerCase() || PageTypes.OTHER,
        },
        event: cookieEvent,
      },
      true
    );

    close(cookie);
  };

  const onKeyDownHandler = (event: KeyboardEvent, changedCookieSetting: object) => {
    if (event.key === ' ' || event.keyCode === 32) {
      event.preventDefault();
      setCookieSettings(prev => ({
        ...prev,
        ...changedCookieSetting,
      }));
    }
  };

  const cookieSettingsEl = (
    <>
      <S.CookieTitle component="h1" variant="h3" tabIndex={0}>
        {title || t('title')}
      </S.CookieTitle>
      <S.Subtitle component="h2" variant="h3">
        {necessaryCookiesTitle || t('necessaryCookiesTitle')}
      </S.Subtitle>
      <S.SettingGroup>
        <S.OnOffSwitch>
          <S.CheckMark>
            <CheckIcon />
          </S.CheckMark>
        </S.OnOffSwitch>
        <S.OnOff variant="body">{onLabel || t('onLabel')}</S.OnOff>
        <S.CookieContent>
          {necessaryCookiesContent || t('necessaryCookiesContent')}
          <br />
          <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
        </S.CookieContent>
      </S.SettingGroup>
      <S.Subtitle component="h2" variant="h3">
        {analyticsCookiesTitle || t('analyticsCookiesTitle')}
      </S.Subtitle>
      <S.SettingGroup>
        <S.OnOffSwitch data-testid="cookie-setting-analytics">
          <S.Switch
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCookieSettings(prev => ({
                ...prev,
                analytics: e.target.checked,
              }))
            }
            checked={cookieSettings.analytics}
            onKeyDown={(event: KeyboardEvent) =>
              onKeyDownHandler(event, { analytics: !cookieSettings.analytics })
            }
          />
        </S.OnOffSwitch>
        <S.OnOff variant="body">
          {cookieSettings.analytics ? onLabel || t('onLabel') : offLabel || t('offLabel')}
        </S.OnOff>
        <S.CookieContent>
          {analyticsCookiesContent || t('analyticsCookiesContent')}
          <br />
          <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
        </S.CookieContent>
      </S.SettingGroup>
      <S.Subtitle component="h2" variant="h3">
        {marketingRetargetingCookiesTitle || t('marketingRetargetingCookiesTitle')}
      </S.Subtitle>
      <S.SettingGroup>
        <S.OnOffSwitch data-testid="cookie-setting-marketing">
          <S.Switch
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCookieSettings(prev => ({ ...prev, marketingAndRetargeting: e.target.checked }))
            }
            checked={cookieSettings.marketingAndRetargeting}
            onKeyDown={(event: KeyboardEvent) =>
              onKeyDownHandler(event, {
                marketingAndRetargeting: !cookieSettings.marketingAndRetargeting,
              })
            }
          />
        </S.OnOffSwitch>
        <S.OnOff variant="body">
          {cookieSettings.marketingAndRetargeting
            ? onLabel || t('onLabel')
            : offLabel || t('offLabel')}
        </S.OnOff>
        <S.CookieContent>
          {marketingRetargetingCookiesContent || t('marketingRetargetingCookiesContent')}
          <br />
          <S.Link href="/cookie-statement">{moreInfo || t('moreInfo')}</S.Link>
        </S.CookieContent>
      </S.SettingGroup>
      <S.Buttons>
        <S.YesToAllBtn
          label={yesToAllBtn || t('yesToAllBtn')}
          ordinal="secondary"
          onClick={() => setCocoCookies(true, 'cookie-change')}
          testId="cookie-wall-accept-all"
        />
        <S.YesBtn
          label={yesBtn || t('yesBtn')}
          ordinal="success"
          onClick={() => setCocoCookies(false, 'cookie-change')}
          testId="cookie-wall-accept-selected"
        />
      </S.Buttons>
    </>
  );

  return (
    <FocusLock>
      <S.CookieWrapper $enableCookieWallV2={enableCookieWallV2}>
        <S.Cookie
          $settingsOpen={settingsOpen}
          $enableCookieWallV2={enableCookieWallV2}
          data-testid="cookie-wall"
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {settingsOpen ? (
            cookieSettingsEl
          ) : enableCookieWallV2 ? (
            <CookieWallV2Element
              changeSettingsHandler={() => setSettingsOpen(true)}
              refuseOptionalHandler={() => setCocoCookies(false, 'cookie-reject')}
              acceptAllHandler={() => setCocoCookies(true, 'cookie-accept')}
              titleV2={titleV2}
              contentV2Paragraph1={contentV2Paragraph1}
              affiliatesLink={affiliatesLink}
              affiliates={affiliates}
              clickHereLink={clickHereLink}
              clickHere={clickHere}
              contentV2Paragraph2={contentV2Paragraph2}
              ourPartnersLink={ourPartnersLink}
              ourPartners={ourPartners}
              contentV2Paragraph3={contentV2Paragraph3}
              cookieStatement={cookieStatement}
              privacyStatementLink={privacyStatementLink}
              privacyStatement={privacyStatement}
              preferencesBtn={preferencesBtn}
              refuseOptionalBtn={refuseOptionalBtn}
              acceptAllBtn={acceptAllBtn}
            />
          ) : (
            <CookieWallElement
              changeSettingsHandler={() => setSettingsOpen(true)}
              yesButtonHandler={() => setCocoCookies(true, 'cookie-accept')}
              title={title}
              content={content}
              changeSettings={changeSettings}
              moreInfo={moreInfo}
              confirmation={confirmation}
              yesBtn={yesBtn}
            />
          )}
        </S.Cookie>
      </S.CookieWrapper>
    </FocusLock>
  );
};
