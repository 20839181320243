import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialTwitterInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512,1024 C794.769792,1024 1024,794.769792 1024,512 C1024,229.230208 794.769792,0 512,0 C229.230208,0 0,229.230208 0,512 C0,794.769792 229.230208,1024 512,1024 Z M741.141687,394.492625 C741.335187,399.561875 741.434188,404.678937 741.434188,409.748187 C741.434188,565.943188 622.568937,746.068062 405.211062,746.068062 C338.4935,746.068062 276.306312,726.477313 224.061875,692.946688 C233.272812,694.067188 242.6795,694.554875 252.23075,694.554875 C307.595375,694.554875 358.523562,675.645313 398.972937,643.967 C347.264562,643.090063 303.59825,608.828188 288.586812,561.993875 C295.800312,563.310125 303.207312,564.041938 310.810062,564.041938 C321.629187,564.041938 332.059625,562.628938 341.95175,559.90025 C287.85725,548.982125 247.212125,501.320938 247.212125,444.007812 L247.212125,442.546438 C263.09825,451.414813 281.276562,456.67925 300.722187,457.312063 C268.9955,436.113125 248.136875,400.001188 248.136875,358.965125 C248.136875,337.326312 253.935687,316.955375 264.122,299.606187 C322.408812,371.052687 409.499562,418.0805 507.699687,423.002375 C505.653875,414.378125 504.6785,405.411313 504.6785,396.053 C504.6785,330.844625 557.604125,277.870625 622.860875,277.870625 C656.830812,277.870625 687.581562,292.295938 709.123062,315.250438 C735.975687,309.986 761.316875,300.093875 784.223562,286.497125 C775.352937,314.079313 756.542375,337.326313 732.271625,351.995188 C756.151437,349.073 778.862375,342.6875 800.061875,333.329188 C784.223562,356.967688 764.241875,377.826312 741.141687,394.492625 Z" />
      </g>
    </g>
  </Svg>
);

export default SocialTwitterInvertedIcon;
