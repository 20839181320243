import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialLinkedinInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512,1024 C794.769792,1024 1024,794.769792 1024,512 C1024,229.230208 794.769792,0 512,0 C229.230208,0 0,229.230208 0,512 C0,794.769792 229.230208,1024 512,1024 Z M295.859649,240 C259.929825,240 224,266.969072 224,302.927835 C224,338.886598 259.929825,365.85567 295.859649,365.85567 C331.789474,365.85567 367.719298,338.886598 367.719298,302.927835 C367.719298,266.969072 331.789474,240 295.859649,240 Z M234.105263,784.438144 L359.298246,784.438144 L359.298246,415.860825 L233.54386,415.860825 L234.105263,784.438144 Z M655.719298,406.871134 C590.37193,406.871134 564.322807,444.178351 547.929825,469.798969 L547.929825,415.860825 L422.175439,415.860825 L422.175439,784.438144 L547.929825,784.438144 L547.929825,559.695876 C547.929825,535.536082 578.582456,501.6 612.715789,501.6 C657.291228,501.6 673.684211,536.097938 673.684211,586.664948 L673.684211,784.438144 L799.438596,784.438144 L799.438596,568.685567 C799.438596,453.168041 736.785965,406.871134 655.719298,406.871134 Z" />
      </g>
    </g>
  </Svg>
);

export default SocialLinkedinInvertedIcon;
