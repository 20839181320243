import { useRef, useEffect } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';
import { media, colors } from '../../core/styles';
import type { ButtonProps } from '../../core/button/Button';
import SearchIcon from '../../core/icons/SearchIcon';

const S = {
  Toggle: styled.button`
    position: relative;
    cursor: pointer;
    top: 0;
    padding: 0;
    height: 54px;
    min-width: 18px;
    border: none;
    display: inline-block;

    @media ${media.greaterThan('sm')} {
      padding: 0 10px;
    }

    @media ${media.greaterThan('xl')} {
      background: ${colors.NERO_GREY};
    }
  `,
  Icon: styled.i`
    display: inline-block;
    width: 12px;

    path {
      fill: ${colors.WHITE};
    }

    @media ${media.greaterThan('xl')} {
      width: 16px;
    }
  `,
};

export const ToggleSearch = (props: ButtonProps): ReactElement => {
  const toggleRef = useRef<HTMLButtonElement>(null);

  const setFocusOnSearchInput = () => {
    const inputElement: HTMLElement | null = document.querySelector('input#searchBox_input');

    if (inputElement) {
      inputElement.focus();
    }
  };

  useEffect(() => {
    const refValue = toggleRef.current;

    if (refValue) {
      refValue.addEventListener('click', setFocusOnSearchInput);
    }

    return () => {
      if (refValue) {
        refValue.removeEventListener('click', setFocusOnSearchInput);
      }
    };
  }, [toggleRef]);

  return (
    <S.Toggle {...props} ref={toggleRef} data-testid="search-toggle" aria-label="search-toggle">
      <S.Icon>
        <SearchIcon />
      </S.Icon>
    </S.Toggle>
  );
};
