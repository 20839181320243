import type { ReactElement } from 'react';
import { memo } from 'react';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';
import { media, colors } from '../../core/styles';
import { getCategoryPathsFromPDPUrl } from '../../../../utilities/ssr';
import { LinkTarget, NavigationType } from '../../../../utilities/graphql/codegen';
import { FlyoutNavigation } from '../flyoutMenu/FlyoutNavigation';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { URL_REGEX } from '../../../../utilities/constants/regex';
import { useProductListContext } from '../../../../utilities/context/static/ProductListContext';

const S = {
  NavLinks: styled.div<{ $isFlyout: boolean }>`
    display: none;
    height: 54px;
    left: 70px;
    position: absolute;
    top: 0;
    white-space: nowrap;

    @media ${media.greaterThan('sm')} {
      display: block;
    }

    @media ${media.greaterThan('lg')} {
      position: ${({ $isFlyout }) => ($isFlyout ? 'unset' : 'absolute')};
      padding-left: ${({ $isFlyout }) => ($isFlyout ? '32px' : 0)};
    }
  `,

  NavLink: styled.a<{ $active: boolean }>`
    font-size: 14px;
    font-weight: 700;
    line-height: 54px;
    margin: 0 4px;
    padding: 0 8px;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    border-color: ${({ $active }) => ($active ? colors.WHITE : 'unset')};

    &:hover,
    &:focus {
      span {
        border-color: ${colors.WHITE};
      }
    }

    @media ${media.lessThan('lg')} {
      &:last-child {
        display: none;
      }
    }
  `,

  LinkLabel: styled.span<{ $active: boolean }>`
    border-bottom: 2px solid ${({ $active }) => ($active ? colors.WHITE : 'transparent')};
    color: ${colors.WHITE};
  `,
};

const NavLinksComponent = (): ReactElement => {
  const { locale } = useAppContext();
  const {
    configuration: { navigationType },
    flyoutNavigation,
    outletFlyoutNavigation,
  } = useStaticContext();
  const { setFlyoutItem } = useNavActionContext();
  const {
    product: { url },
  } = usePDPContext();

  const { categoryDetails, isPLP } = useProductListContext();

  const currentUrl = isPLP ? categoryDetails?.requestUrl : url;
  const { superCategory } = getCategoryPathsFromPDPUrl(currentUrl);
  const navLinks = [
    ...(flyoutNavigation.navigation || []),
    ...(outletFlyoutNavigation.navigation || []),
  ].filter(item => item?.label && item.url && !item.children);

  const validateFullUrl = (url: string) => (URL_REGEX.test(url) ? url : `/${locale}${url}`);

  return (
    <S.NavLinks
      $isFlyout={navigationType === NavigationType.HorizontalMainNavigationFlyOut}
      data-cs-capture=""
    >
      {navLinks.map((link, i) =>
        link?.url && link.label ? (
          <S.NavLink
            href={validateFullUrl(link.url)}
            $active={superCategory?.toLowerCase() === link.label.toLowerCase()}
            key={i}
            target={link.target === LinkTarget.Samewindow ? '_self' : '_blank'}
            onMouseEnter={() => {
              if (navigationType === NavigationType.HorizontalMainNavigationFlyOut) {
                setTimeout(() => {
                  if (link.label) {
                    setFlyoutItem(link.label);
                  }
                }, 500);
              }
            }}
          >
            <S.LinkLabel
              $active={superCategory?.toLowerCase() === link.label.toLowerCase()}
              data-testid={`top-navigation-link${
                superCategory?.toLowerCase() === link.label.toLowerCase() ? '-active' : ''
              }`}
            >
              {link.label}
            </S.LinkLabel>
          </S.NavLink>
        ) : (
          <></>
        )
      )}
      {navigationType === NavigationType.HorizontalMainNavigationFlyOut && <FlyoutNavigation />}
    </S.NavLinks>
  );
};

export const NavLinks = memo(NavLinksComponent, isEqual);
