import { Environments } from '.';

const qubitStagingId = '5776';
const qubitProductionId = '5775';

export const smartserveScriptIds: { [k: string]: string } = {
  [Environments.DEV]: qubitStagingId,
  [Environments.TST]: qubitStagingId,
  [Environments.ACC]: qubitStagingId,
  [Environments.PRD]: qubitProductionId,
};
