import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialTiktokInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g
      transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path
        d="M4825 10224 c-22 -2 -92 -8 -155 -14 -392 -34 -875 -136 -1211 -254 -194 -69 -234 -85 -327 -128 -46 -21 -87 -38 -92 -38 -18 0 -346 -167 -480
  -245 -281 -162 -566 -366 -793 -566 -99 -87 -453 -442 -519 -519 -26 -30 -69 -81 -96 -112 -89 -104 -294 -393 -378 -533 -192 -319 -264 -458 -391 -765 -61
  -146 -165 -445 -182 -525 -5 -22 -27 -110 -49 -195 -75 -288 -121 -609 -142 -988 -16 -286 -2 -572 45 -922 34 -247 49 -327 97 -510 22 -85 44 -173 49
  -195 18 -84 119 -375 182 -525 19 -47 49 -116 65 -155 87 -207 305 -596 446 -795 104 -148 220 -303 261 -351 22 -26 62 -73 90 -105 116 -136 509 -519 645
  -629 194 -155 495 -363 645 -445 28 -15 70 -39 95 -54 69 -41 393 -199 510 -249 105 -46 397 -151 500 -181 267 -77 481 -124 735 -160 276 -39 359 -46
  635 -53 343 -8 484 2 984 72 306 43 836 198 1114 327 46 21 87 38 92 38 18 0 346 167 480 245 74 43 180 108 235 145 55 37 123 82 150 100 368 246 872 737
  1157 1125 111 151 154 215 231 340 252 411 395 716 538 1148 63 190 144 522 170 692 11 77 24 167 29 200 43 289 58 793 32 1070 -29 313 -77 607 -134 825
  -17 63 -38 149 -49 192 -17 72 -99 313 -155 458 -64 166 -244 541 -305 636 -21 33 -39 64 -39 68 0 9 -146 239 -207 326 -121 173 -249 340 -336 436 -319
  352 -666 666 -932 844 -27 18 -95 63 -150 100 -139 94 -360 220 -548 312 -87 43 -163 78 -167 78 -5 0 -46 17 -92 38 -305 142 -806 283 -1209 342 -302 44
  -393 51 -714 54 -176 2 -338 2 -360 0z m1652 -1714 c10 -261 43 -403 142 -620 82 -181 180 -320 331 -470 146 -146 286 -249 442 -326 204 -100 478 -174 642
  -174 l86 0 0 -590 c0 -465 -3 -590 -12 -590 -7 0 -96 7 -198 15 -274 24 -397 47 -690 133 -160 46 -492 203 -605 284 -33 24 -77 54 -97 67 l-38 23 0 -1256
  c0 -1340 0 -1354 -50 -1581 -52 -239 -82 -327 -170 -510 -199 -415 -519 -752 -931 -980 -158 -87 -322 -152 -496 -195 -261 -65 -540 -85 -763 -56 -138 18
  -394 74 -480 105 -103 36 -295 121 -350 155 -30 19 -73 44 -95 56 -99 55 -295 212 -383 308 -24 26 -67 72 -95 102 -123 130 -207 254 -316 470 -145 285 -230
  638 -231 955 0 131 26 386 50 490 99 431 295 781 611 1092 136 133 255 225 407 314 132 77 355 178 450 203 348 94 460 110 732 103 107 -2 203 -8 213 -12
  16 -7 17 -45 17 -611 0 -375 -4 -604 -10 -604 -5 0 -35 7 -67 15 -106 28 -224 37 -325 26 -179 -20 -326 -69 -446 -148 -81 -54 -98 -68 -184 -155 -94 -96
  -142 -167 -196 -289 -65 -148 -74 -194 -79 -379 -5 -153 -3 -173 21 -267 29 -115 105 -279 169 -363 135 -177 354 -323 547 -365 191 -41 302 -43 484 -5
  338 69 634 356 734 710 l27 95 2 2435 c2 1339 5 2441 8 2448 3 10 128 12 597 10 l593 -3 2 -65z"
      />
    </g>
  </Svg>
);

export default SocialTiktokInvertedIcon;
