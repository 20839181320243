import type { ReactElement } from 'react';
import { memo, useState } from 'react';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';
import { LoginButton } from '../login/LoginButton';
import { colors, media } from '../../core/styles';
import { SideNavItem } from './SideNavItem';
import { MessageBoxNotification } from '../messageBox/MessageBoxNotification';
import type { HeaderOpenModalProps } from '../../core/layouts/MasterLayout';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { getCategoryPathsFromPDPUrl } from '../../../../utilities/ssr';
import { usePDPContext } from '../../../../utilities/context/static/PDPContext';
import { getActiveNavItems } from '../../../../utilities/helpers';
import type { GlobalMessageBoxNotification } from '../../../../amplienceTypes/schemas/imported/global-message-box-notification-schema';
import { useProductListContext } from '../../../../utilities/context/static/ProductListContext';

interface SideNavigationProps {
  loginLabel?: string;
  messageBoxNotification?: GlobalMessageBoxNotification;
}

const S = {
  SideNavContainer: styled.div`
    background-color: ${colors.WHITE};
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    position: relative;
    padding-bottom: 54px;
    overflow: hidden;

    &:hover,
    &:focus {
      overflow-y: auto;
    }

    @media ${media.lessThan('sm')} {
      overflow-y: scroll;
    }
  `,
  LoginPanel: styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: ${colors.NERO_GREY};
    padding: 0 16px;
    box-sizing: border-box;

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,
};

const SideNavigationComponent = ({
  loginLabel,
  messageBoxNotification,
  openedModal,
  setOpenedModal,
}: SideNavigationProps & HeaderOpenModalProps): ReactElement => {
  const {
    product: { url },
  } = usePDPContext();
  const { categoryDetails, isPLP } = useProductListContext();
  const {
    leftNavigation,
    configuration: { showMessageBoxNotifications },
  } = useStaticContext();

  const { plpPath } = getCategoryPathsFromPDPUrl(url);
  // Because fit filtered requestUrl looks like this /shop/men/jeans/skinny/fit/skinny
  const filteredPLPPath = categoryDetails?.requestUrl?.includes('/fit/')
    ? categoryDetails?.requestUrl?.split('/').slice(0, -2).join('/')
    : categoryDetails?.requestUrl;
  const plpPathUrl = isPLP ? filteredPLPPath || '' : plpPath;
  const activeNodeInfo = getActiveNavItems(leftNavigation, plpPathUrl);
  const [activeIndexArray, setActiveIndexArray] = useState(activeNodeInfo?.indexArray ?? []);
  const activeItemUrl = activeNodeInfo?.navItem?.url;

  return (
    <S.SideNavContainer data-cs-capture="">
      {leftNavigation.navigation?.map((item, index) => (
        <SideNavItem
          {...item}
          key={index}
          deepLevel={0}
          activeIndexArray={activeIndexArray}
          currentIndex={`${index}`}
          updateActiveItem={setActiveIndexArray}
          activeItemUrl={activeItemUrl}
        />
      ))}
      <S.LoginPanel data-cs-capture="">
        <LoginButton loginLabel={loginLabel} />
        {messageBoxNotification && showMessageBoxNotifications && (
          <MessageBoxNotification
            {...messageBoxNotification}
            openedModal={openedModal}
            setOpenedModal={setOpenedModal}
          />
        )}
      </S.LoginPanel>
    </S.SideNavContainer>
  );
};

export const SideNavigation = memo(SideNavigationComponent, isEqual);
