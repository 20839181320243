import type { ReactElement } from 'react';
import styled from 'styled-components';
import { media, colors } from '../../core/styles';
import ShoppingBagIcon from '../../core/icons/ShoppingBagIcon';
import { Typography } from '../../core/typography/Typography';
import { useCartContext } from '../../../../utilities/context/dynamic/CartContext';

const S = {
  ShoppingCartButton: styled.button`
    height: 54px;
    min-width: 43.484px;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-left: 10px;

    &:hover,
    &:focus {
      path {
        fill: ${colors.DARK_GREY};
      }

      span {
        color: ${colors.DARK_GREY};
      }
    }

    @media ${media.greaterThan('sm')} {
      min-width: 52.484px;
    }
  `,

  Amount: styled(Typography)`
    color: ${colors.WHITE};
    padding-left: 4px;
  `,

  IconWrapper: styled.i`
    display: inline-block;
    width: 16px;
    height: 16px;

    @media ${media.greaterThan('sm')} {
      width: 18px;
      height: 18px;
    }
  `,
};

export const ShoppingCartButton = (): ReactElement => {
  const { cartHovered, setCartHovered, miniCart } = useCartContext();

  const handleClick = () => {
    window.location.href = '/checkout/shopping-bag';
  };

  return (
    <S.ShoppingCartButton
      onMouseEnter={() => setCartHovered(true)}
      onMouseLeave={() => setCartHovered(false)}
      onClick={handleClick}
      aria-label={`Shopping bag contains ${miniCart.totalItems || '0'} ${
        (miniCart.totalItems || 0) === 1 ? 'item' : 'items'
      }`}
      aria-controls="cartHeader"
      aria-haspopup="true"
      aria-expanded={cartHovered}
      data-cs-capture=""
    >
      <S.IconWrapper data-testid="mini-cart-icon">
        <ShoppingBagIcon color={colors.WHITE} />
      </S.IconWrapper>
      {!!miniCart?.totalItems && (
        <S.Amount component="span" variant="title" testId="mini-cart-count" data-cs-capture="">
          {miniCart.totalItems}
        </S.Amount>
      )}
    </S.ShoppingCartButton>
  );
};
