import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const EmailIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    enableBackground="new 0 0 1024 1024"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g>
      <path
        fill={color}
        d="M0,880h1024V252.848L576.613,616.558c-15.81,16.013-41.138,26.687-68.086,26.687c-26.953,0-52.281-10.674-71.328-29.736
			L0,263.333V880z"
      />
      <path
        fill={color}
        d="M520.238,516.861L978.671,144H32.093l461.196,369.715C502.336,522.292,514.715,522.39,520.238,516.861z"
      />
    </g>
  </Svg>
);

export default EmailIcon;
