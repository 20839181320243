import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { GlobalCookieWallSettings as GlobalCookieWallSettingsSchema } from '../../../amplienceTypes/schemas/imported/global-cookie-wall-settings-schema';
import { colors, media, spacing } from '../core/styles';
import { Typography } from '../core/typography/Typography';
import { Button } from '../core/button/Button';
import PreferencesIcon from '../core/icons/PreferencesIcon';

type CookieWallElementProps = {
  changeSettingsHandler: () => void;
  refuseOptionalHandler: () => void;
  acceptAllHandler: () => void;
};

const S = {
  CookieV2Title: styled(Typography)`
    font-size: 21px;
    font-weight: 800;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;

    @media ${media.greaterThan('sm')} {
      margin-bottom: 10px;
    }
  `,

  CookieContent: styled(Typography)`
    margin-bottom: 13px;

    & :not(:last-child) {
      margin-bottom: 20px;
    }

    @media ${media.lessThan('sm')} {
      overflow: auto;
      background: linear-gradient(${colors.WHITE} 33%, rgba(255 255 255 / 0%)),
        linear-gradient(rgba(255 255 255 / 0%), ${colors.WHITE} 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(84 84 84 / 40%), rgba(0 0 0 / 0%)),
        radial-gradient(farthest-side at 50% 100%, rgba(84 84 84 / 40%), rgba(0 0 0 / 0%)) 0 100%;
      background-color: ${colors.WHITE};
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      background-size: 100% 66px, 100% 66px, 100% 22px, 100% 22px;
    }
  `,

  Text: styled.div``,

  LinkV2: styled.a`
    text-decoration: underline;
    color: ${colors.BLUE_CHARCOAL};

    &:hover,
    &:focus {
      color: ${colors.SUCCESS_BLUE};
    }
  `,

  ButtonsV2: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 11px;

    @media (max-width: 519px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,

  PreferencesButton: styled.button`
    font-weight: 400;
    color: ${colors.BLUE_CHARCOAL};
    text-transform: none;
    text-decoration: underline;
    display: flex;
    align-items: center;
    gap: ${spacing.XXXS};
    min-width: 0;
    padding: 0 8px;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus &:hover svg {
      color: ${colors.SUCCESS_BLUE};
      stroke: ${colors.SUCCESS_BLUE};
    }

    @media (max-width: 519px) {
      padding-top: 18px;
      order: 2;
    }
  `,

  IconWrapper: styled.i`
    display: block;
    width: 16px;
    height: 16px;

    svg {
      display: block;
      height: 16px;
      width: 16px;
    }
  `,

  QuickButons: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: ${spacing.XXXS};
  `,

  CookieV2Button: styled(Button)`
    &.button--secondary {
      font-size: 13px;
      font-weight: 700;
      color: ${colors.BLUE_CHARCOAL};
      text-transform: none;
      line-height: 20px;
      min-width: 0;
      padding: 10px 16px;
      border: 1px solid ${colors.BLUE_CHARCOAL};
      transition: background-color 0.2s, color 0.2s ease;

      @media (prefers-reduced-motion) {
        transition: none;
      }

      &:hover,
      &:focus {
        color: ${colors.WHITE};
        background-color: ${colors.SUCCESS_BLUE};
      }
    }
  `,
};

const CookieWallV2Element = ({
  changeSettingsHandler,
  refuseOptionalHandler,
  acceptAllHandler,
  titleV2,
  contentV2Paragraph1,
  affiliatesLink,
  affiliates,
  clickHereLink,
  clickHere,
  contentV2Paragraph2,
  ourPartnersLink,
  ourPartners,
  contentV2Paragraph3,
  cookieStatement,
  privacyStatementLink,
  privacyStatement,
  preferencesBtn,
  refuseOptionalBtn,
  acceptAllBtn,
}: CookieWallElementProps & Partial<GlobalCookieWallSettingsSchema>) => {
  const { t } = useTranslation('common', { keyPrefix: 'globalCookieWallSettings' });

  return (
    <>
      <S.CookieV2Title component="h1" variant="h3" tabIndex={0}>
        {titleV2 || t('titleV2')}
      </S.CookieV2Title>

      <S.CookieContent component="div" variant="body">
        <S.Text>
          {(contentV2Paragraph1 || t('contentV2Paragraph1'))?.split('[affiliates]')[0]}
          <S.LinkV2 href={affiliatesLink || t('affiliatesLink')}>
            {affiliates || t('affiliates')}
          </S.LinkV2>
          {
            (contentV2Paragraph1 || t('contentV2Paragraph1'))
              ?.split('[affiliates]')[1]
              ?.split('[click here]')[0]
          }
          <S.LinkV2 href={clickHereLink || t('clickHereLink')}>
            {clickHere || t('clickHere')}
          </S.LinkV2>
          {
            (contentV2Paragraph1 || t('contentV2Paragraph1'))
              ?.split('[affiliates]')[1]
              ?.split('[click here]')[1]
          }
        </S.Text>
        <S.Text>
          {(contentV2Paragraph2 || t('contentV2Paragraph2'))?.split('[our partners]')[0]}
          <S.LinkV2 href={ourPartnersLink || t('ourPartnersLink')}>
            {ourPartners || t('ourPartners')}
          </S.LinkV2>
          {(contentV2Paragraph2 || t('contentV2Paragraph2'))?.split('[our partners]')[1]}
        </S.Text>
        <S.Text>
          {(contentV2Paragraph3 || t('contentV2Paragraph3'))?.split('[cookie statement]')[0]}
          <S.LinkV2 href="/cookie-statement">{cookieStatement || t('cookieStatement')}</S.LinkV2>
          {
            (contentV2Paragraph3 || t('contentV2Paragraph3'))
              ?.split('[cookie statement]')[1]
              ?.split('[privacy statement]')[0]
          }
          <S.LinkV2 href={privacyStatementLink || t('privacyStatementLink')}>
            {privacyStatement || t('privacyStatement')}
          </S.LinkV2>
          {
            (contentV2Paragraph3 || t('contentV2Paragraph3'))
              ?.split('[cookie statement]')[1]
              ?.split('[privacy statement]')[1]
          }
        </S.Text>
      </S.CookieContent>
      <S.ButtonsV2>
        <S.PreferencesButton
          onClick={changeSettingsHandler}
          data-testid="cookie-wall-change-settings"
        >
          <S.IconWrapper>
            <PreferencesIcon color={colors.BLUE_CHARCOAL} />
          </S.IconWrapper>
          {preferencesBtn || t('preferencesBtn')}
        </S.PreferencesButton>

        <S.QuickButons>
          <S.CookieV2Button
            label={refuseOptionalBtn || t('refuseOptionalBtn')}
            ordinal="secondary"
            onClick={refuseOptionalHandler}
            testId="cookie-wall-refuse-optional"
          />
          <S.CookieV2Button
            label={acceptAllBtn || t('acceptAllBtn')}
            ordinal="secondary"
            onClick={acceptAllHandler}
            testId="cookie-wall-accept"
          />
        </S.QuickButons>
      </S.ButtonsV2>
    </>
  );
};

export default CookieWallV2Element;
