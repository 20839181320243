import type { MouseEvent, ReactElement } from 'react';
import { useState, memo } from 'react';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import type { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import type { GlobalCart as GlobalCartProps } from '../../../../amplienceTypes/schemas/imported/global-cart-schema';
import { NextLink } from '../../core/link/NextLink';
import { media, colors, animations } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { Button } from '../../core/button/Button';
import type { ImageEntry, CartEntry } from '../../../../utilities/graphql/codegen';
import { useRemoveFromCartMutation } from '../../../../utilities/graphql/codegen';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { useCartContext } from '../../../../utilities/context/dynamic/CartContext';
import {
  CART_ENTRIES,
  CART_MUTATED,
  CART_STORAGE_VALUE,
  HYBRIS_CART_STORAGE_VALUE,
} from '../../../../utilities/constants/localStorageKeys';
import {
  parseCartUpdateDataLayer,
  getProductDataLayerEvent,
  triggerQubitEvent,
} from '../../../../utilities/vendors';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { storageAvailable } from '../../../../utilities/helpers';
import CartUnreservedItemsComponent from './CartUnreservedItemsComponent';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';

const S = {
  Cart: styled.div<{ $hovered: boolean }>`
    background: ${colors.WHITE};
    position: absolute;
    text-align: center;
    right: 0;
    left: 0;
    z-index: -1;
    box-sizing: border-box;
    visibility: ${({ $hovered }) => ($hovered ? 'visible' : 'hidden')};
    transform: ${({ $hovered }) => ($hovered ? 'translateY(0)' : 'translateY(-600px)')};
    transition: visibility 0.2s linear, transform 0.2s linear;

    @media ${media.greaterThan('sm')} {
      padding: 20px 25px;
      left: unset;
      width: 480px;
    }

    @media ${media.greaterThan('xl')} {
      padding: 25px 40px;
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  EmptyTitle: styled(Typography)`
    text-transform: lowercase;
    padding: 30px 0;
  `,

  ContinueShopping: styled(NextLink)`
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 20px;
    min-width: 133px;
    line-height: 36px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    overflow: hidden;
    transition: 75ms background-color cubic-bezier(0.84, 0.12, 0.51, 0.81),
      75ms color cubic-bezier(0.84, 0.12, 0.51, 0.81),
      75ms border-color cubic-bezier(0.84, 0.12, 0.51, 0.81);
    text-transform: lowercase;
    color: ${colors.WHITE};
    border-color: ${colors.SUCCESS_BLUE};
    background: ${colors.SUCCESS_BLUE};
    font-size: 13px;
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${colors.SUCCESS_BLUE};
      background-color: transparent;
    }
  `,

  MobileCart: styled.div`
    display: block;

    @media ${media.greaterThan('sm')} {
      display: none;
    }
  `,

  ProductAddedTitle: styled(Typography)`
    font-weight: 700;
    padding: 32px 0 6px;
    text-align: center;
  `,

  CheckoutArea: styled.div`
    margin-top: 25px;
    padding: 0 12px;
    display: block;

    @media ${media.greaterThan('sm')} {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      padding: 0;
    }
  `,

  Checkout: styled(Button)`
    width: 100%;
    font-size: 13px;
    margin-bottom: 13px;
    order: 2;

    @media ${media.greaterThan('sm')} {
      margin: 0;
      height: fit-content;
      width: auto;
      margin-left: auto;
    }
  `,

  RewardArea: styled.div`
    margin: 30px 0 15px;
    order: 1;

    @media ${media.greaterThan('sm')} {
      margin: 0;
    }
  `,

  RewardTitle: styled(Typography)`
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    text-transform: uppercase;
  `,

  RewardDescription: styled(Typography)`
    font-weight: 400;
  `,

  SignupLink: styled(NextLink)`
    color: ${colors.ACCESSIBILITY_GREY};
  `,

  DesktopCart: styled.div`
    display: none;

    @media ${media.greaterThan('sm')} {
      display: block;
      margin-right: -10px;
      margin-top: 10px;
      max-height: 330px;
      text-align: left;
      overflow-y: auto;
      overflow-x: hidden;
    }
  `,

  CartItem: styled.div`
    position: relative;
    height: 90px;
    padding-left: 90px;
    padding-bottom: 10px;
    box-sizing: border-box;
  `,

  ProductImage: styled.div`
    position: absolute;
    left: 0;
  `,

  ProductDescription: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,

  ProductInfo: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  `,

  PriceCross: styled.div`
    display: flex;
    align-items: flex-start;
    gap: 5px;
  `,

  ActualPrice: styled(Typography)`
    line-height: 18px;
  `,

  PrevPrice: styled(Typography)`
    color: ${colors.ACCESSIBILITY_GREY};
    line-height: 18px;
  `,

  ProductTitle: styled(Typography)`
    line-height: 17px;
  `,

  CrossIcon: styled.div`
    font-size: 13px;
    margin-top: -4px;
    color: ${colors.ACCESSIBILITY_GREY};

    &:hover,
    &:focus {
      color: ${colors.NERO_GREY};
    }
  `,

  LoadingIcon: styled.div`
    width: 16px;
    height: 16px;
    background-image: var(--spinner);
    background-repeat: no-repeat;
    animation: ${animations.spin} 0.6s linear infinite;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  `,

  Category: styled(Typography)`
    padding-top: 5px;
    font-weight: 400;
  `,

  TotalArea: styled.div`
    display: none;

    @media ${media.greaterThan('sm')} {
      display: block;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid ${colors.ACCESSIBILITY_GREY};
    }
  `,

  SubArea: styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  `,

  Subtotal: styled(Typography)`
    width: 190px;
    text-align: right;
    text-transform: none;
  `,

  Total: styled.div`
    margin-top: 42px;
  `,
};

const MiniCartComponent = ({
  emptyCart,
  productAdded,
  subtotal,
  total,
  shipping,
  freeLabel,
  rawRewards,
  signUp,
  rewardText,
  continueShopping,
  checkout,
  discount,
  tax,
  oneSizeLabel,
  itemsNotReservedMessage,
}: GlobalCartProps): ReactElement => {
  const { t } = useTranslation('common', { keyPrefix: 'globalCart' });

  const { locale, country } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { cartHovered, setCartHovered } = useCartContext();
  const {
    configuration: { loyaltyPointSystem, enableExponea, enableCookieWall, showStrikethroughPrices },
  } = useStaticContext();
  const { miniCart, setMiniCart } = useCartContext();
  const [removing, setRemoving] = useState<{ [k: number]: boolean }>({});
  const [removeFromCartMutation] = useRemoveFromCartMutation();
  const router = useRouter();

  const cartProducts = miniCart.entries;
  const makeImageValue = (
    simplifiedImages?: (ImageEntry | null | undefined)[] | null,
    shouldUseDarkBackgroundImgs?: boolean | null
  ) => {
    const image = simplifiedImages?.find(img => {
      const imageKey = shouldUseDarkBackgroundImgs ? 'M01' : 'Z01';

      return img?.key === imageKey;
    });

    return {
      url: image?.value?.url?.replace('{{dimensions}}', 'h_95'),
      alt: image?.value?.altText,
    };
  };

  const removeFromCart = async (cartEntry?: CartEntry | null) => {
    if (
      !cartEntry ||
      cartEntry?.entryNumber === undefined ||
      cartEntry?.entryNumber === null ||
      Number.isNaN(Number(cartEntry?.entryNumber))
    ) {
      // TODO Error Handle Entry Number missing in the cart product response
      return;
    }

    try {
      setRemoving({ [cartEntry?.entryNumber]: true });

      const result = await removeFromCartMutation({
        variables: {
          entryNumber: cartEntry?.entryNumber,
          locale,
        },
      });
      const cartData = result.data;

      if (cartData?.removeFromCart?.cart) {
        if (storageAvailable('localStorage')) {
          localStorage.setItem(`${country}${CART_MUTATED}`, CART_STORAGE_VALUE);
          localStorage.setItem(
            `${CART_ENTRIES}_${locale}`,
            JSON.stringify(cartData.removeFromCart.cart)
          );
        }

        triggerQubitEvent(
          'ecBasketItemAction',
          {
            action: 'remove',
            productId: cartEntry.product?.baseProduct || cartEntry.product?.code,
            quantity: cartEntry.quantity,
          },
          enableCookieWall
        );

        setMiniCart(cartData.removeFromCart.cart);

        const eecRemoveFromCartEvent = getProductDataLayerEvent('eecRemoveFromCart', cartEntry);

        if (eecRemoveFromCartEvent) {
          pushToDataLayer(eecRemoveFromCartEvent);
        }

        if (enableExponea) {
          const exponeaCartUpdateProperties = parseCartUpdateDataLayer(
            'remove',
            cartData.removeFromCart,
            cartEntry.product
          );

          pushToDataLayer({
            event: 'exponea_cart_update',
            event_name: 'cart_update',
            namespace: 'exponea',
            event_properties: exponeaCartUpdateProperties,
          });
        }
      }

      setRemoving({ [cartEntry?.entryNumber]: false });
    } catch (e) {
      if ((e as ApolloError)?.message?.includes('440')) {
        if (storageAvailable('localStorage')) {
          localStorage.setItem(`${country}${CART_MUTATED}`, HYBRIS_CART_STORAGE_VALUE);
        }

        router.reload();
      } else {
        setRemoving({ [cartEntry?.entryNumber]: false });
        // eslint-disable-next-line no-console
        console.log('Error - RemoveFromCart via MiniCart', e);
      }
    }
  };

  const removeLocaleFromUrl = (url?: string | null) => {
    const splitUrl = url?.split('/');
    const modifiedUrl = splitUrl?.slice(2);

    return `/${modifiedUrl?.join('/')}`;
  };

  const emptyCartEl = (
    <>
      <S.EmptyTitle component="p" variant="h4" alignment="center" testId="mini-cart-empty-label">
        {emptyCart || t('emptyCart')}
      </S.EmptyTitle>
      <S.ContinueShopping href={`/${locale}`} data-testid="continue-shopping-button">
        {continueShopping || t('continueShopping')}
      </S.ContinueShopping>
    </>
  );

  const fullCartEl = (
    <>
      {/* Mobile Cart */}
      <S.MobileCart>
        <S.ProductAddedTitle component="p" variant="body">
          {productAdded || t('productAdded')}
        </S.ProductAddedTitle>
        {cartHovered && <CartUnreservedItemsComponent text={itemsNotReservedMessage} />}
      </S.MobileCart>

      {/* Desktop Cart */}
      <S.DesktopCart>
        {cartHovered && <CartUnreservedItemsComponent text={itemsNotReservedMessage} />}
        {cartProducts?.map((cartProduct, i) => {
          const { url, alt } = makeImageValue(
            cartProduct?.product?.simplifiedImages,
            cartProduct?.product?.shouldUseDarkBackgroundImgs
          );

          return (
            <NextLink href={removeLocaleFromUrl(cartProduct?.product?.url)} key={i}>
              <S.CartItem>
                {url && alt && (
                  <S.ProductImage data-testid="mini-cart-product-image">
                    <Image src={url} alt={alt} width={80} height={80} layout="fixed" />
                  </S.ProductImage>
                )}
                <S.ProductDescription>
                  <S.ProductInfo>
                    <S.ProductTitle
                      component="h5"
                      variant="h4"
                      testId="mini-cart-product-name"
                      data-cs-capture=""
                    >
                      {cartProduct?.product?.name}
                    </S.ProductTitle>
                    <S.PriceCross>
                      <div>
                        {showStrikethroughPrices &&
                          cartProduct?.totalPrice?.value !==
                            cartProduct?.totalPriceWithoutDiscount?.value && (
                            <S.PrevPrice
                              component="p"
                              variant="h5"
                              decoration="line-through"
                              testId="mini-cart-before-price"
                              data-cs-capture=""
                            >
                              {
                                cartProduct?.totalPriceWithoutDiscount
                                  ?.formattedValueWithoutCurrency
                              }
                            </S.PrevPrice>
                          )}
                        <S.ActualPrice
                          component="p"
                          variant="h5"
                          testId="mini-cart-product-price"
                          data-cs-capture=""
                        >
                          {cartProduct?.gift
                            ? freeLabel || t('freeLabel')
                            : cartProduct?.totalPrice?.formattedValueWithoutCurrency}
                        </S.ActualPrice>
                      </div>
                      {cartProduct &&
                      cartProduct.entryNumber !== undefined &&
                      cartProduct.entryNumber !== null &&
                      !Number.isNaN(Number(cartProduct.entryNumber)) &&
                      removing[cartProduct.entryNumber] ? (
                        <S.LoadingIcon />
                      ) : (
                        <S.CrossIcon
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeFromCart(cartProduct);
                          }}
                          data-testid="mini-cart-remove-product"
                          data-cs-capture=""
                        >
                          &#215;
                        </S.CrossIcon>
                      )}
                    </S.PriceCross>
                  </S.ProductInfo>
                  <S.Category
                    component="p"
                    variant="body"
                    testId="mini-cart-product-category"
                    data-cs-capture=""
                  >
                    {`${cartProduct?.product?.materialGroup} / ${
                      cartProduct?.product?.color?.description
                    } / ${
                      cartProduct?.product?.oneSize
                        ? oneSizeLabel || t('oneSizeLabel')
                        : cartProduct?.product?.size?.formattedValue
                    } / ${cartProduct?.quantity}`}
                  </S.Category>
                </S.ProductDescription>
              </S.CartItem>
            </NextLink>
          );
        })}
      </S.DesktopCart>

      <S.TotalArea>
        <S.SubArea>
          <S.Subtotal component="span" variant="body">
            {subtotal || t('subtotal')}
          </S.Subtotal>
          <S.ActualPrice
            component="span"
            variant="h5"
            testId="mini-cart-subtotal"
            data-cs-capture=""
          >
            {miniCart.subTotal?.formattedValueWithoutCurrency}
          </S.ActualPrice>
        </S.SubArea>
        {!!miniCart.totalDiscounts?.value &&
          miniCart.totalDiscounts?.value > 0 &&
          miniCart.appliedOrderPromotions && (
            <S.SubArea>
              <S.Subtotal component="span" variant="body">
                {discount || t('discount')}
              </S.Subtotal>
              <S.ActualPrice
                component="span"
                variant="h5"
                testId="mini-cart-discount"
                data-cs-capture=""
              >
                -{miniCart.totalDiscounts.formattedValueWithoutCurrency}
              </S.ActualPrice>
            </S.SubArea>
          )}
        <S.SubArea>
          <S.Subtotal component="span" variant="body">
            {shipping || t('shipping')}
          </S.Subtotal>
          <S.ActualPrice
            component="span"
            variant="h5"
            testId="mini-cart-shipping"
            data-cs-capture=""
          >
            {miniCart.deliveryCost?.value && miniCart.deliveryCost?.value > 0
              ? miniCart.deliveryCost?.formattedValueWithoutCurrency
              : freeLabel || t('freeLabel')}
          </S.ActualPrice>
        </S.SubArea>
        {miniCart.externalTaxEnabled && (
          <S.SubArea>
            <S.Subtotal component="span" variant="body">
              {tax || t('tax')}
            </S.Subtotal>
            <S.ActualPrice component="span" variant="h5" testId="mini-cart-tax" data-cs-capture="">
              {miniCart.totalTax?.formattedValueWithoutCurrency}
            </S.ActualPrice>
          </S.SubArea>
        )}
        <S.Total>
          <S.SubArea>
            <S.Subtotal component="span" variant="body">
              {total || t('total')}
            </S.Subtotal>
            <S.ActualPrice
              component="span"
              variant="h5"
              testId="mini-cart-total"
              data-cs-capture=""
            >
              {miniCart.totalPrice?.formattedValue}
            </S.ActualPrice>
          </S.SubArea>
        </S.Total>
      </S.TotalArea>
      <S.CheckoutArea>
        <S.Checkout
          label={checkout || t('checkout')}
          ordinal="success"
          href={`/${locale}/checkout/shopping-bag`}
          testId="mini-cart-checkout"
          data-cs-capture=""
          hardReload
        />
        {!miniCart.customer?.loyaltySubscribed && loyaltyPointSystem === 'POINT_SYSTEM_GLOBAL' && (
          <S.RewardArea data-testid="mini-cart-rewards">
            <S.RewardTitle component="p" variant="h3" color={colors.PRODUCT_BLUE}>
              {rawRewards || t('rawRewards')}
            </S.RewardTitle>
            <S.RewardDescription component="p" variant="body">
              <S.SignupLink href="/account" testId="mini-cart-rewards-sign-up" data-cs-capture="">
                {signUp || t('signUp')}
              </S.SignupLink>{' '}
              {rewardText || t('rewardText')}
            </S.RewardDescription>
          </S.RewardArea>
        )}
      </S.CheckoutArea>
    </>
  );

  return (
    <S.Cart
      $hovered={cartHovered}
      onMouseEnter={() => setCartHovered(true)}
      onMouseLeave={() => setCartHovered(false)}
      data-testid="mini-cart"
      id="cartHeader"
      data-cs-capture=""
    >
      {miniCart.totalItems && miniCart.totalItems > 0 ? fullCartEl : emptyCartEl}
    </S.Cart>
  );
};

export const MiniCart = memo(MiniCartComponent, isEqual);
