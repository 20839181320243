import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialPinterestInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512,1024 C794.769792,1024 1024,794.769792 1024,512 C1024,229.230208 794.769792,0 512,0 C229.230208,0 0,229.230208 0,512 C0,794.769792 229.230208,1024 512,1024 Z M371.820559,777.812187 C370.865232,753.365375 371.670637,723.950562 378.007358,697.247 C384.746499,668.83625 423.224478,506.150562 423.224478,506.150562 C423.224478,506.150562 411.956692,483.714125 411.956692,450.634625 C411.956692,398.682687 442.185042,359.779625 479.7556,359.779625 C511.742992,359.779625 527.134183,383.773625 527.134183,412.485312 C527.134183,444.611375 506.714421,492.547062 496.15059,537.021687 C487.300147,574.217 514.81131,604.534625 551.576464,604.534625 C618.217144,604.534625 663.031279,519.201687 663.031279,418.1075 C663.031279,341.207562 611.227194,283.632875 516.773253,283.632875 C410.146361,283.632875 343.75649,362.940875 343.75649,451.638125 C343.75649,482.15825 352.80927,503.741937 366.94191,520.406 C373.430243,528.036875 374.337099,531.19925 372.02346,539.983812 C370.312889,546.408687 366.389567,561.969687 364.881334,568.143687 C362.51697,577.02725 355.224923,580.189062 347.226666,576.926 C298.088478,556.847562 275.255881,503.2385 275.255881,442.903625 C275.255881,343.215687 359.450342,223.748562 526.48039,223.748562 C660.666916,223.748562 748.987037,320.726937 748.987037,424.732625 C748.987037,562.32125 672.335432,665.119812 559.321658,665.119812 C521.398839,665.119812 485.690464,644.641437 473.467351,621.400625 C473.467351,621.400625 453.049279,702.216125 448.723536,717.8255 C441.279876,744.882875 426.643365,771.886812 413.365729,792.969875 C413.365729,792.969875 403.709317,805.167125 384.597141,796.834812 C384.596578,796.835938 372.223543,791.615375 371.820559,777.812187 Z" />
      </g>
    </g>
  </Svg>
);

export default SocialPinterestInvertedIcon;
