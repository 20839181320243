import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialFacebookInvertedIcon = ({
  color = colors.BLACK,
  hoverColor,
}: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512,1024 C794.769792,1024 1024,794.769792 1024,512 C1024,229.230208 794.769792,0 512,0 C229.230208,0 0,229.230208 0,512 C0,794.769792 229.230208,1024 512,1024 Z M578.594864,333.69875 L667.197477,333.69875 L667.197477,223.969063 L578.594864,223.969063 C505.107985,223.969063 445.594174,288.542375 445.594174,367.89875 L445.594174,423.430438 L356.940926,423.430438 L356.940926,533.447563 L445.594174,533.447563 L445.594174,799.15625 L556.299619,799.969063 L556.299619,533.447563 L667.19804,533.447563 L667.19804,423.430438 L556.299056,423.430438 L556.299056,366.892438 C556.299056,347.903563 568.070054,333.69875 578.594864,333.69875 Z" />
      </g>
    </g>
  </Svg>
);

export default SocialFacebookInvertedIcon;
