import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { colors } from '../styles';
import { Svg } from './styles';

const SearchIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <path
      fill={color}
      d="M152.986,457.995c0-168.423,137.385-306.591,305.972-306.591c168.84,0,305.974,138.167,305.974,306.591
	c0,168.297-137.134,306.591-305.974,306.591C290.372,764.586,152.986,626.292,152.986,457.995z M1004.476,882.582L830.981,723.614
	c54.477-75.388,86.937-165.649,86.937-265.619C917.918,205.36,712.216,0,458.958,0C205.827,0,0,205.36,0,457.995
	C0,710.377,205.827,917.88,458.958,917.88c92.094,0,177.897-31.263,249.861-77.655l185.193,168.423
	c23.779,22.188,61.145,20.171,82.407-5.042l32.584-37.315C1030.644,942.086,1028.631,903.762,1004.476,882.582z"
    />
  </Svg>
);

export default SearchIcon;
