import type { ReactElement } from 'react';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import { colors } from '../../core/styles';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { NextLink } from '../../core/link/NextLink';
import type {
  WishlistSimplifiedImage,
  WishlistProduct,
} from '../../../../utilities/context/dynamic/WishlistContext';

const S = {
  Product: styled.div`
    min-height: 68px;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
    z-index: 1;
    grid-template-columns: min-content 1fr max-content;
  `,
  ResultImage: styled.div`
    width: 50px;
    aspect-ratio: 11/15;
    display: block;
    position: relative;
  `,
  ProductName: styled.div`
    color: ${colors.NERO_GREY};
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    &[data-nowrap='true'] {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `,
  Price: styled.div<{ $color: string | undefined }>`
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: ${({ $color }) => $color || colors.BLACK};
  `,
  DiscountPrice: styled.div`
    line-height: 16px;
    color: ${colors.RAVEN_GREY};
  `,
  WithoutCurrency: styled.div`
    text-decoration: line-through;
    color: ${colors.RAVEN_GREY};
  `,
  Color: styled.div`
    color: ${colors.RAVEN_GREY};
    text-transform: none;
    font-style: normal;
  `,
  LowestPriceCopy: styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    color: ${colors.RAVEN_GREY};
  `,
  ProductInfo: styled.div<{ $align: string }>`
    display: grid;
    grid-auto-rows: max-content;
    gap: 4px;
    text-align: ${({ $align }) => $align};
  `,
};

type MiniWishlistItemProps = {
  product: WishlistProduct;
  index: number;
};

export const MiniWishlistItem = ({ product, index }: MiniWishlistItemProps): ReactElement => {
  const {
    configuration: { showStrikethroughPrices, enableDarkBgImages },
    quickViewContent,
  } = useStaticContext();

  const productPrice = quickViewContent?.productPrice?.content;

  const plainFromPrice = product?.fromPrice?.value || 0;
  const plainBasePrice = product?.price?.value || 0;

  const formattedLowestPrice = product?.formattedLowestPrice;
  const formattedFromPrice = product?.fromPrice?.formattedValue;
  const formattedBasePrice = product?.price?.formattedValue;

  const percentOriginalPrice = product.formattedOriginalPriceDiscountPercentage;
  const percentLowestPrice = product.formattedLowestPriceDiscountPercentage;

  const getWishlistItemImageUrl = (simplifiedImages?: WishlistSimplifiedImage[]): string => {
    let url = '';

    simplifiedImages?.forEach(img => {
      if (img.key === 'Z01' && img?.value?.url) {
        url = img.value.url.replace('{{dimensions}}', 'h_68,w_48') || '';
      }
    });

    if (enableDarkBgImages) {
      simplifiedImages?.forEach(img => {
        if (img.key === 'M01' && img?.value?.url) {
          url = img.value.url.replace('{{dimensions}}', 'h_68,w_48') || '';
        }
      });
    }

    return url;
  };

  return (
    <NextLink key={index} href={product?.url || ''}>
      <S.Product>
        <S.ResultImage>
          <Image
            src={getWishlistItemImageUrl(product?.simplifiedImages)}
            layout="fill"
            blurDataURL={imageBlurData}
            placeholder="blur"
            data-testid="wishlist-product-image"
          />
        </S.ResultImage>

        <S.ProductInfo $align="left">
          <S.ProductName data-nowrap={!showStrikethroughPrices} data-testid="wishlist-product-name">
            {product.name}
          </S.ProductName>
          {formattedLowestPrice && (
            <>
              {productPrice?.originalPrice && formattedFromPrice ? (
                <S.LowestPriceCopy data-testid="wishlist-product-original-price">
                  <>
                    {productPrice?.originalPrice} {formattedFromPrice} {percentOriginalPrice}
                  </>
                </S.LowestPriceCopy>
              ) : null}

              {productPrice?.lowestPrice30days && formattedLowestPrice ? (
                <S.LowestPriceCopy data-testid="wishlist-product-lowest-price">
                  <>
                    {productPrice?.lowestPrice30days} {formattedLowestPrice} {percentLowestPrice}
                  </>
                </S.LowestPriceCopy>
              ) : null}
            </>
          )}
        </S.ProductInfo>

        <S.ProductInfo $align="right">
          <S.Price
            data-testid="wishlist-product-price"
            $color={
              plainBasePrice < plainFromPrice ? (productPrice?.basePriceColor as string) : undefined
            }
          >
            {formattedBasePrice}
          </S.Price>
        </S.ProductInfo>
      </S.Product>
    </NextLink>
  );
};
