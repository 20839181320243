import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SocialEmailInvertedIcon = ({ color = colors.BLACK, hoverColor }: IconProps): ReactElement => (
  <Svg
    version="1.1"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    overflow="inherit"
    xmlSpace="preserve"
    $hoverColor={hoverColor}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={color}>
        <path d="M512,1024 C794.769792,1024 1024,794.769792 1024,512 C1024,229.230208 794.769792,0 512,0 C229.230208,0 0,229.230208 0,512 C0,794.769792 229.230208,1024 512,1024 Z M548.237375,570.711396 C539.344812,579.723641 525.097813,585.730302 509.939,585.730302 C494.777938,585.730302 480.530937,579.723641 469.817,568.995127 L223.892562,371.924931 L223.892562,718.971273 L799.892562,718.971273 L799.892562,366.024163 L548.237375,570.711396 Z M241.944875,304.766808 L501.367625,512.833633 C506.456563,517.660816 513.41975,517.716016 516.526438,514.605102 L774.395,304.766808 L241.944875,304.766808 Z" />
      </g>
    </g>
  </Svg>
);

export default SocialEmailInvertedIcon;
